import {GetStaticProps} from 'next';
import {MainContent} from '@components/MainContent';
import {LoginForm} from '@liveComponents/Auth/LoginForm';
import {Container} from '@components/Container';
import {useRouter} from 'next/router';
import {asValue} from '@utils/query';
import {getLocalizedRoute, routes} from '@utils/routes';
import {useLocale} from '@utils/locale';
import Head from 'next/head';
import {useEffect, useMemo} from 'react';
import {TouchPointEnum} from '@liveComponents/Auth/utils';
import {SeoHeader} from '@pageComponents/Layout/SeoHeader';
import {useIntl} from 'react-intl';
import OgImage from '@images/homepage/cover_OG_1232x660px.jpg';

export const getStaticProps: GetStaticProps = () => {
    return {
        props: {},
    };
};

const LoginPage = () => {
    const {query} = useRouter();
    const router = useRouter();
    const locale = useLocale();
    const intl = useIntl();

    const callbackUrl = useMemo(
        () => (query.callbackUrl ? asValue(query.callbackUrl) : getLocalizedRoute(routes.homepage, locale)),
        [query.callbackUrl, locale],
    );

    const personify = useMemo(() => asValue(query.personify), [query.personify]);

    useEffect(() => {
        // ignore when logging out (i.e. url has error=SessionRequired)
        if (typeof query.callbackUrl === 'string' && !query.error) {
            const domainLocale = router.domainLocales?.find((dl) => dl.defaultLocale === locale);
            const lastUrl = (domainLocale?.http ? 'http' : 'https') + '://' + domainLocale?.domain + router.basePath + query.callbackUrl;
            localStorage.setItem('lastUrl', lastUrl);
        }
    }, [locale, query.callbackUrl, query.error, router.basePath, router.domainLocales]);

    return (
        <>
            <SeoHeader
                title={intl.formatMessage({defaultMessage: 'Přihlášení na Bezrealitky.cz | Kompletní správa inzerátů, reakcí a služeb'})}
                description={intl.formatMessage({
                    defaultMessage:
                        'Přihlaste se na Bezrealitky.cz a spravujte své inzeráty, odpovědi od zájemců i majitelů, hlídacího psa a zakoupené služby. Mějte vše pod kontrolou na jednom místě.',
                })}
                image={OgImage.src}
                hrefLangRoute={{route: routes.login}}
                canonical={{route: routes.login}}
            />
            <MainContent>
                {callbackUrl && (
                    <Head>
                        <meta name="robots" content="noindex, follow" key="robots" />
                    </Head>
                )}
                <Container variant="thin" className="py-4">
                    <LoginForm
                        id={TouchPointEnum.Page}
                        redirect={true}
                        personify={personify}
                        callbackUrl={callbackUrl}
                        onRegister={() => void router.push(getLocalizedRoute(routes.register, locale))}
                        onForgottenPassword={() => void router.push(getLocalizedRoute(routes.resetPassword, locale))}
                    />
                </Container>
            </MainContent>
        </>
    );
};

export default LoginPage;
